/* eslint-disable */

import React, { useEffect, useState } from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";

import InfoArea from "components/InfoArea/InfoArea.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/exampleStyle.js";
import BlockContent from "@sanity/block-content-to-react";

import { useParams } from "react-router-dom";
import MainImage from "../Projects/main-image";
//import Carousel from "./Carousel";

import client from "sanityClient";
import imageUrlBuilder from "@sanity/image-url";

import './styles.css'

const useStyles = makeStyles(styles);
const builder = imageUrlBuilder(client);

const serializers = {
  types: {
    mainImage: ({ node }) => <MainImage mainImage={node} />,
  },
};

function isEven(value) {
	if (value%2 == 0)
		return true;
	else
		return false;
}

function urlFor(source) {
  return builder.image(source);
}


export default function SectionExamples() {
  const classes = useStyles();
  const [services, setservices] = useState([]);
  //const params = {minSeats: 2}

  useEffect(() => {
    const query = `*[_type == 'services']`;
    async function getData() {
      const data = await client.fetch(query);
      console.log("data", data);
      if (data.mainImage === "undefined") data.mainImage = ''
      setservices(data);
    }
    getData();
  }, []);
  return (
    <div className={classes.section, "wrapper"}>
      <div className={classes.container} style={{
        margin: "auto",
        maxWidth: "1200px"
      }}>
        <h1
          id="tjenester"
          style={{
            marginBottom: "50px"
          }}
        >Tjenester</h1>
        <div 
        className="services">
          {services && services.length > 0
            ? services.map((service, i) => (
                (isEven(i) ? (
                  <div key={i} 
                    className="service"
                  >
                    <div style={{
                      width: '250px',
                      padding: '5px'
                    }}>
                    <h3 >{service?.title ? service?.title : ""}</h3>
                    <BlockContent
                      blocks={service?.description}
                      serializers={serializers}
                      />
                    </div>
                    {service?.mainImage ? (
                      <img src={urlFor(service?.mainImage)} style={{
                        width: '230px',
                        objectFit: 'cover'
                      }}/>
                    ) : ""}
                  </div>
                ) : (
                  <div key={i}
                    className="service"
                  >
                    <div style={{
                      width: '250px',
                      padding: '5px'
                    }}>
                    <h3 >{service?.title ? service?.title : ""}</h3>
                    <BlockContent
                      blocks={service?.description}
                      serializers={serializers}
                      />
                    </div>
                    {service?.mainImage ? (
                      <img src={urlFor(service?.mainImage)} style={{
                        width: '230px',
                        objectFit: 'cover'
                      }}/>
                    ) : ""}
                  </div>
                ) 
                )
              ))
            : ""}
        </div>
      </div>
    </div>
  );
}
