/* eslint-disable */

import React, { useEffect, useState } from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";

import InfoArea from "components/InfoArea/InfoArea.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/exampleStyle.js";

import { useParams } from "react-router-dom";
import MainImage from "./main-image";
import Carousel from "./Carousel";

import client from "sanityClient";
import imageUrlBuilder from "@sanity/image-url";

import './styles.css'

const useStyles = makeStyles(styles);

export default function SectionExamples() {
  const classes = useStyles();
  const [projects, setProjects] = useState([]);
  //const params = {minSeats: 2}

  useEffect(() => {
    const query = `*[_type == 'sampleProject']{
        title, 
        excerpt, 
        "mainImage": mainImage.asset->url,
        slug
    }`;
    async function getData() {
      const data = await client.fetch(query);
      console.log("data", data);
      if (data.mainImage === "undefined") data.mainImage = ''
      setProjects(data);
    }
    getData();
  }, []);
  return (
    <div className={classes.section} 
    style={{
      padding: "70px 0",
      margin: "auto",
      maxWidth: "1200px"
    }}>
      <div className={classes.container}>
        <h1 
          id="tidligere-arbeid"
          style={{
            marginBottom: "50px"
          }}
        >Tidligere arbeid</h1>
        <div className="previews">
          {projects && projects.length > 0
            ? projects.map((project, i) => (
                <Link to={`project/${project?.slug?.current}`} key={i}>
                  <div>
                    <InfoArea
                      title={project?.title ? project?.title : ""}
                      description={project?.excerpt || ""}
                      image={project?.mainImage ? project?.mainImage : ""}
                      iconColor="rose"
                    />
                  </div>
                </Link>
              ))
            : ""}
        </div>
      </div>
    </div>
  );
}
