/* eslint-disable */

import React/* , { useEffect, useState }  */from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MainImage from "../../views/Components/Projects/main-image";

import styles from "assets/jss/material-kit-react/components/infoStyle.js";
// import client from "sanityClient";

import BlockContent from "@sanity/block-content-to-react";
const serializers = {
  types: {
    mainImage: ({ node }) => <MainImage mainImage={node} />,
  },
};

const useStyles = makeStyles(styles);

export default function InfoArea(props) {
  const classes = useStyles();
  const { title, description, iconColor, vertical, image } = props;
 /*  const [data, setData] = useState([]);
  //const params = {minSeats: 2}

  useEffect(() => {
    const query = `*[]`;
    async function getData() {
      const data = await client.fetch(query);
      console.log("data", data);
      setData(data);
    }
    getData();
  }, []); */
  const iconWrapper = classNames({
    [classes.iconWrapper]: true,
    [classes[iconColor]]: true,
    [classes.iconWrapperVertical]: vertical,
  });
  const iconClasses = classNames({
    [classes.icon]: true,
    [classes.iconVertical]: vertical,
  });
  return (
    <div className={classes.infoArea} style={{
      display: "flex",
      textAlign: 'left'
    }}>
      <div className={iconWrapper}>
        {console.log('image', image)}
        {image ? (
          <img
            src={image}
            style={{
              width: "160px",
              height: "130px",
              objectFit: "cover"
            }}
          />
        ) : ''}
      </div>
      <div className={classes.descriptionWrapper}>
        <h4 className={classes.title}>{title ? title : ""}</h4>
        {/* <p className={classes.description}>{description ? description : ""}</p> */}
        <BlockContent
          blocks={description ? description : ""}
          serializers={serializers}
        />
      </div>
    </div>
  );
}

/* InfoArea.defaultProps = {
  iconColor: "gray",
};

InfoArea.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string.isRequired,
  description: PropTypes.any,
  iconColor: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  vertical: PropTypes.bool,
  image: PropTypes.any,
}; */
