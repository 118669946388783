/* eslint-disable */
/* eslint-disable no-underscore-dangle */
import React from "react";

const MainImage = ({ mainImage, width = 1200, className }) => {
  if (!mainImage || !mainImage.asset) {
    return <></>;
  }

  return mainImage.preparedImageUrl ? (
    <img src={mainImage.preparedImageUrl} alt={mainImage.alt || ""} />
  ) : (
    <></>
  );
};

export default MainImage;
