/* eslint-disable */

import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/icons/Timeline";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer";
// import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
/* import SectionBasics from "./Sections/SectionBasics.js";
import SectionNavbars from "./Sections/SectionNavbars.js";
import SectionTabs from "./Sections/SectionTabs.js";
import SectionPills from "./Sections/SectionPills.js";
import SectionNotifications from "./Sections/SectionNotifications.js";
import SectionTypography from "./Sections/SectionTypography.js";
import SectionJavascript from "./Sections/SectionJavascript.js";
import SectionCarousel from "./Sections/SectionCarousel.js";
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
import SectionLogin from "./Sections/SectionLogin.js";
import SectionExamples from "./Sections/SectionExamples.js";
import SectionDownload from "./Sections/SectionDownload.js"; */
import InfoArea from "components/InfoArea/InfoArea.js";

import Preview from "./Projects/Preview.js";
import Services from "./Services/Services.js";

import styles from "assets/jss/material-kit-react/views/components.js";

import client from "sanityClient";
import imageUrlBuilder from "@sanity/image-url";

import './styles.css'

const builder = imageUrlBuilder(client);
const useStyles = makeStyles(styles);

// eslint-disable-next-line no-unused-vars
function urlFor(source) {
  return builder.image(source);
}

export default function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [settings, setSettings] = useState();
  //const params = {minSeats: 2}

  useEffect(() => {
    const query = `*[_type == 'siteSettings'] {
      title, 
      mainTitle,
      description, 
      "mainImage": mainImage.asset->url
    }
    `;
    async function getData() {
      const data = await client.fetch(query /* , params */);
      setSettings(data);
    }
    getData();
  }, []);

  return (
    <div>
      <Header
        brand={settings && settings[0].title ? settings[0].title : ""}
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax image={settings ? settings[0]?.mainImage : ""}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 /* className={classes.title} */
                style={{"fontWeight": "600"}}>
                  {settings && settings[0]?.mainTitle ? settings[0].mainTitle : ""}
                </h1>
                <h3 className={classes.subtitle}>
                  {settings && settings[0]?.description
                    ? settings[0].description
                    : ""}
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
      <br/>
      <h1
        id="kontakt-oss"
        style={{
            marginBottom: "50px",
            textAlign: 'center'
          }}
        >Kontakt oss</h1>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <h3 style={{
            textAlign: 'center',
            color: 'black',
            width: '230px',
            margin: '20px',
            padding: '0 4px',
          }}>
            På epost
          </h3>
          <div className="btn" align="center">
            <a className="btn" href="mailto:kontakt@terrassepleie.org">
              kontakt@terrassepleie.org
            </a>
          </div>
          <h3 style={{
            textAlign: 'center',
            color: 'black',
            width: '230px',
            margin: '20px',
            padding: '4px',
          }}>
            Eller på telefon
          </h3>
          <div className="btn" align="center">
              <a className="btn" href="tel:99366938">
              +47 993 66 938
              </a>
          </div>
        </div>
        <Services />
        <Preview />
      </div>
      <Footer />
    </div>
  );
}
