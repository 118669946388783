/* eslint-disable */

import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
//import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

// import image3 from "assets/img/bg3.jpg";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

import client from "sanityClient";
import imageUrlBuilder from "@sanity/image-url";

const useStyles = makeStyles(styles);

const builder = imageUrlBuilder(client);

// eslint-disable-next-line no-unused-vars
function urlFor(source) {
  return builder.image(source);
}

export default function SectionCarousel({ images }) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                {images && images.length > 0
                  ? images.map((image, i) => (
                      <div key={i}>
                        {console.log(image)}
                        <img
                          src={urlFor(image.asset)}
                          alt={image.alt}
                          className="slick-image"
                        />
                        <div className="slick-caption">
                          <h4>{image.caption}</h4>
                        </div>
                      </div>
                    ))
                  : ""}
                {/* <div>
                  <img src={image3} alt="Third slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Yellowstone National Park, United States
                    </h4>
                  </div>
                </div> */}
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
