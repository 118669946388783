/* eslint-disable */

import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/icons/Timeline";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
// import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
/* import SectionBasics from "./Sections/SectionBasics.js";
import SectionNavbars from "./Sections/SectionNavbars.js";
import SectionTabs from "./Sections/SectionTabs.js";
import SectionPills from "./Sections/SectionPills.js";
import SectionNotifications from "./Sections/SectionNotifications.js";
import SectionTypography from "./Sections/SectionTypography.js";
import SectionJavascript from "./Sections/SectionJavascript.js";
import SectionCarousel from "./Sections/SectionCarousel.js";
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
import SectionLogin from "./Sections/SectionLogin.js";
import SectionExamples from "./Sections/SectionExamples.js";
import SectionDownload from "./Sections/SectionDownload.js"; */
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/components.js";

import client from "sanityClient";
import imageUrlBuilder from "@sanity/image-url";

// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
// @material-ui/icons
import BlockContent from "@sanity/block-content-to-react";
// core components
import Button from "components/CustomButtons/Button.js";

import landing from "assets/img/landing.jpg";
import profile from "assets/img/profile.jpg";

// sections for this page

import { useParams } from "react-router-dom";
import MainImage from "./main-image";
import Carousel from "./Carousel";

const useStyles = makeStyles(styles);

const serializers = {
  types: {
    mainImage: ({ node }) => <MainImage mainImage={node} />,
  },
};

const builder = imageUrlBuilder(client);

// eslint-disable-next-line no-unused-vars
function urlFor(source) {
  return builder.image(source);
}

function getHumanDate(date) {
  const newDate = new Date(Date.parse(date))
  console.log('date ', newDate)
  console.log(date)
  console.log(' getDate', newDate.getDate())
  console.log(' getMonth', newDate.getMonth() + 1)
  console.log(' getFullYear', newDate.getFullYear())
  return newDate.getDate() + '.' + (newDate.getMonth() + 1) + '.' + newDate.getFullYear()
}

export default function SectionExamples(props) {
  let { id } = useParams();
  const classes = useStyles();
  const { ...rest } = props;

  const [project, setProject] = useState();
  //const params = {minSeats: 2}

  useEffect(() => {
    const query = `*[_type == 'sampleProject' && slug.current == '${id}'] {
      title,
      mainImage,
      excerpt,
      imageGallery,
      body,
      startedAt,
      endedAt,
      "headerTitle": *[_type == 'siteSettings'] {
        title
      }
    }
    `;
    async function getData() {
      const data = await client.fetch(query /* , params */);
      setProject(data);
    }
    getData();
  }, []);

  return (
    <div>
      <Header
        brand={ project && project[0]?.headerTitle[0]?.title ? project[0]?.headerTitle[0].title : "Terrassepleie."}
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax image={project ? urlFor(project[0]?.mainImage) : ""}>
        <div className={classes.container}>
          {/* <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>
                  {project && project[0]?.title ? project[0].title : ""}
                </h1>
                <h3 className={classes.subtitle}>
                  {project && project[0]?.description
                    ? project[0].description
                    : ""}
                </h3>
              </div>
            </GridItem>
          </GridContainer> */}
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <div className={classes.container}>
                <h3 className={classes.title}>
                  {project && project[0]?.title ? project[0].title : ""}
                </h3>
                <p>
                  {project && project[0]?.startedAt ? 'Started at: ' + getHumanDate(project[0].startedAt) + ' ' : ""}
                  {project && project[0]?.endedAt ? 'Ended at: ' + getHumanDate(project[0].endedAt) : ""}
                </p>
                {/* <h3 className={classes.subtitle}>
                  {project && project[0]?.excerpt ? (
                    <BlockContent
                      blocks={project[0]?.excerpt}
                      serializers={serializers}
                    />
                  ) : (
                    ""
                  )}
                </h3> */}
          </div>
          <div className={classes.container}>
            {project && project[0]?.body ? (
              <BlockContent
                blocks={project[0]?.body}
                serializers={serializers}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={classes.container}>
          <Carousel
            images={
              project && project[0]?.imageGallery
                ? project[0]?.imageGallery
                : []
            }
          />
        </div>
      </div>
    </div>
  );
}
